<template>
  <div class="main-wrapper">
    <!-- *****Main Cntainer***** -->
    <section class="main_banner_area text-white">
      <!-- <div id="particles-js"></div> -->
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-5 col-md-6 mr-md-auto">
            <div class="info">
              <h1 class="display-4 mb-3 bold">โปรแกรมบริหารบุคคล (biosoft)</h1>
              <p class="mb-5" style="font-size: 16px;">
                Human Resource Management (HRM) ซอฟแวร์ที่พัฒนาเพื่อคุณ!
                ด้วยเทคโนโลยีสมัยใหม่ประสิทธิภาพสูงคุณสมบัติครอบคลุมงานด้านการบริหารงานบุคคล
              </p>
            </div>
            <div class="app-download-area">
              <a
                href="https://play.google.com/store/apps/details?id=com.nextstorymobile"
                target="_blank"
              >
                <v-lazy-image src="assets/images/Biosoft_GooglePlay.png" alt="" style="width: 350px;" />
              </a>
              <a
                href="https://apps.apple.com/th/app/biosoft-plus/id1588614492"
                target="_blank"
              >
                <v-lazy-image src="assets/images/Biosoft_AppStore.png" alt="" style="width: 320px;" />
              </a>
            </div>
          </div>
          <div class="col-12 col-lg-5 col-md-6" style="margin-top: auto;">
            <div class="app-img">
              <!-- <v-lazy-image
                src="https://img-website-biosoft.s3-ap-southeast-1.amazonaws.com/MainBiosoft.png"
                alt="Biosoft Mobile"
              /> -->
              <carousel
                :autoplay="true"
                :responsive="{
                  0: { items: 1, nav: false },
                  600: { items: 1, nav: false },
                }"
              >
                <!-- <img v-for="(img, index) in images" :src="img" :key="index" /> -->
                <img v-for="(img, index) in images" :src="img" :key="index" />
              </carousel>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="clients_testimonial_area bg-light" id="testimonial">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <!-- Heading Text -->
            <div class="section-heading text-center">
              <h2 style="text-transform: lowercase;">biosoft</h2>
              <div class="line-title-center"></div>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-12 col-md-10">
            <div class="slider slider-for">
              <!-- Client testimonial Text  -->
              <div class="client-testimonial-text text-center">
                <div class="client">
                  <i class="fa fa-quote-left" aria-hidden="true"></i>
                </div>
                <div class="client-description text-center">
                  <p>
                    “ YOUR SUCCESS IS OUR SUCCESS. เป็นแนวทางหลักในการทำงาน และ
                    การคิดค้นวิธีการ และเทคโนลียี อันทันสมัย ของทีมงาน เอชพีเอส
                    บริษัทฯ ตระหนักดีว่าลูกค้าของเราล้วนต้องการ "ความสำเร็จ"
                    (Success) ดังนั้น บริษัทฯ
                    ในฐานะผู้ช่วยลูกค้าก็จะช่วยสร้างสรรค์กระบวนการทำงาน
                    ที่กระชับประสานทุกขั้นตอนการทำงาน
                    ให้เป็นหนึ่งเดียวอย่างมีประสิทธิภาพและประสิทธิผล. ”
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="blog" class="section">
	      <!-- Container Starts -->
	      <div class="container">
	        <div class="row">
                <div class="col-12">
                    <!-- Heading Text -->
                    <div class="section-heading text-center">
                        <h2>Our products </h2>
                        <div class="line-title-center"></div>
                    </div>
                </div>
            </div>
	        <div class="row">
	          <div class="col-lg-4 col-md-4 col-xs-12 blog-item">
	            <div class="blog-item-area">
	              <div class="blog-item-img">
	                <a>
	                  <v-lazy-image
                    src="assets/images/windows/WinApp.jpg"
                    alt=""
                  />
	                </a>                 
	              </div>
	              <div class="blog-item-text">
                <h3 style="color:#636d75;">Windows Base
                </h3>
                <p>
                  มีฟังก์ชันการทำงานที่รองรับความต้องการทุกๆด้านของการบริหารทรัพยากรบุคคลในยุคปัจจุบัน
                ได้ถูกออกแบบโดยเน้นความสอดคล้องกับขั้นตอนการปฏิบัติงานจริงให้มากที่สุด.
                </p>
                <a href="#" class="btn btn-blog">อ่านเพิ่มเติม..</a>
              </div>
	            </div>
	          </div>

	          <div class="col-lg-4 col-md-4 col-xs-12 blog-item">
	            <div class="blog-item-area wow fadeInUp" data-wow-delay="0.6s">
	              <div class="blog-item-img">
	                <a>
	                  <v-lazy-image
                    src="assets/images/web/WebBase.jpg"
                    alt=""
                  />
	                </a>                
	              </div>
	              <div class="blog-item-text">
                <h3 style="color:#636d75;">
                  Web Base
                </h3>
                <!-- <div class="meta-tags">
                  <span class="date"
                    ><i class="lnr lnr-calendar-full"></i
                  ></span>
                </div> -->
                <p>
                  Biosoft Plus
                คือเว็บแอพพลิเคชั่นที่พัฒนาด้วยเทคโนโลยีสมัยใหม่ประสิทธิภาพสูงคุณสมบัติครอบคลุมงานด้านการบริหารงานบุคคล
                และยังช่วยลดงานฝ่ายบุคคลได้อย่างมาก.
                </p>
                <a href="#" class="btn btn-blog">อ่านเพิ่มเติม..</a>
              </div>
	            </div>
	            <!-- Blog Item Wrapper Ends-->
	          </div>

	          <div class="col-lg-4 col-md-4 col-xs-12 blog-item">
	            <!-- Blog Item Starts -->
	            <div class="blog-item-area wow fadeInUp" data-wow-delay="0.9s">
	              <div class="blog-item-img">
	                <a>
	                  <v-lazy-image
                    src="assets/images/mobile/Biosoft_Mobile.jpg"
                    alt=""
                  />
	                </a>                
	              </div>
	              <div class="blog-item-text">
                <h3 style="color:#636d75;">
                  Mobile Application
                </h3>
                <!-- <div class="meta-tags">
                  <span class="date"
                    ><i class="lnr lnr-calendar-full"></i
                  ></span>
                </div> -->
                <p>
                  เพื่อตอบโจทย์กับผู้บริหารและผู้ใช้สมัยใหม่ด้วย biosoft next on
                mobile
                ที่จะสนับสนุนและอำนวยความสะดวกให้กับชีวิตการทำงานของพวกเขาและยังช่วยลดงานฝ่ายบุคคลได้อย่างมาก.
                </p>
                <a href="#" class="btn btn-blog">อ่านเพิ่มเติม..</a>
              </div>
	            </div>
	            <!-- Blog Item Wrapper Ends-->
	          </div>
	        </div>
	      </div>
	    </section>
    <HomeFeatures></HomeFeatures>
    <section id="EASY-TO-USE" class="bg-light">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <!-- Section Heading Area -->
            <div class="section-heading text-center">
              <h2>EASY TO USE</h2>
              <div class="line-title-center"></div>
              <p style="font-size: 16px;">
                Biosoft Plus
                  เป็นเว็บแอพพลิเคชั่นที่ถูกออกแบบมาให้ใช้งานง่ายรองรับการแสดงผลทุกอุปกรณ์
                  ทันสมัยใช้งานง่ายเป็นมิตรกับผู้ใช้
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6 col-sm-12">
            <div class="feature-right">
              <div class="feature-single">
                <h4><i class="icon-loop"></i> ลดขั้นตอน</h4>
                <p>พนักงาน Happy จะขอโอที ขอลา ก็ทำได้สะดวก</p>
              </div>
              <div class="feature-single">
                <h4><i class="icon-layers"></i> ลดปริมาณ</h4>
                <p>
                  งาน HR ก็น้อยลงเมื่อพนักงานสามารถทำได้เอง
                  อนุมัติก็ง่ายแถมลดกระดาษด้วย
                </p>
              </div>
              <div class="feature-single">
                <h4><i class="icon-heart"></i> เพิ่มความสุข</h4>
                <p>พนักงานทุกๆฝ่ายก็ Happy</p>
              </div>
            </div>
          </div>

          <div class="col-12 col-lg-6 col-sm-12 center">
            <div class="feature-img text-center">
              <v-lazy-image
                src="assets/images/web/9.png"
                alt="Biosoft Web"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="Why-choose-us" class="bg-light">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <!-- Section Heading Area -->
            <div class="section-heading text-center">
              <h2>ทำไมต้องเลือกเรา?</h2>
              <div class="line-title-center"></div>
              <p style="font-size: 16px;">
                นึกถึง โปรแกรมบริหารงานบุคคล (Human Resource Management) ต้อง
                Biosoft HRM
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-4 col-md-6">
            <div class="how-it-single">
              <h4><i class="icon-support"></i> Support Team</h4>
              <p>
                ทาง Biosoft มีทีมงานที่คอยสนับสนุนการใช้งานของลูกค้า
                ที่เป็นมืออาชีพ ทำงานรวดเร็ว
                และให้ความสำคัญกับความถูกต้องของข้อมูลเป็นสิ่งสำคัญ
                อีกทั้งเรามีระบบ CRM ที่คอยเก็บข้อมูลทำให้
                การทำการทำงานเป็นระบบรวดเร็วและไม่ตกหล่น
              </p>
            </div>
          </div>
          <div class="col-12 col-lg-4 col-md-6">
            <div class="how-it-single">
              <h4><i class="icon-organization"></i> Installation</h4>
              <p>
                โปรแกรม Biosoft
                ของเราสามารถทำการติดตั้งได้ไม่จำกัดจำนวนเครื่องและจำนวนครั้งในใช้งาน
                ถ้าหากมีการเพิ่มพนักงาน เพิ่มเครื่อง ทาง IT
                สามารถทำการติดตั้งได้เองเลย หรือติดต่อมาหาเรา เราติดตั้งให้ฟรี!
              </p>
            </div>
          </div>
          <div class="col-12 col-lg-4 col-md-6">
            <div class="how-it-single">
              <h4><i class="icon-location-pin"></i> Check In</h4>
              <p>
                องค์กรในปัจจุบันมีรูปแบบการลงเวลาเข้างานที่ต่างกันออกไป
                เราเข้าใจในจุดนี้เป็นอย่างดี
                เราเลยมีระบบลงเวลาข้าออกงานได้หลายรูปแบบไม่ว่าจะเป็นการสแกนนิ้ว
                ในกรีณีที่ซื้อเครื่องแสกนนิ้วจากเรา สามารถแสกนนิ้วได้แบบ Real
                Time เลยไม่ต้องเสียเวลาไป Import เข้าโปรแกรมเราอีกที
                หรือการเช็คอินผ่านมือถือด้วย App Mobile ของเรา
              </p>
            </div>
          </div>
          <div class="col-12 col-lg-4 col-md-6">
            <div class="how-it-single">
              <h4><i class="icon-speedometer"></i> Advanced Payroll</h4>
              <p>
                เราเป็นระบบที่สามารถคำนวณได้ตั้งแต่ต้นจนจบบน Application
                WindowsBase ด้วยข้อมูลและฟีเจอร์ที่ครบถ้วน
                รวมไปถึงออกเอกสารต่างๆเช่น ภงด. ไฟล์ประกันสังคม ไฟล์ Payroll
                สำหรับธนาคาร และยังออกใบแจ้งเงินเดือนในรูปแบบ E-pay slip ทั้งบน
                Web Base และ Mobile Base
              </p>
            </div>
          </div>
          <div class="col-12 col-lg-4 col-md-6">
            <div class="how-it-single">
              <h4><i class="icon-globe"></i> Manager Self Service</h4>
              <p>
                แน่นอนระบบ Employee Self Service เรามีอยู่แล้ว
                แต่จะดีกว่าไหมถ้ามีการอำนวยความสะดวกให้กับผู้จัดการในการ
                ขอ/อนุมัติ เช่นการลา ขอโอที ทั้งแบบเป็นรายบุคคลและเป็นกลุ่ม
              </p>
            </div>
          </div>
          <div class="col-12 col-lg-4 col-md-6">
            <div class="how-it-single">
              <h4><i class="icon-wrench"></i> Customized Software</h4>
              <p>
                เนื่องด้วยโปรแกรม Biosoft
                เป็นโปรแกรมที่เราพัฒนาขึ้นมาเองดังนั้นจึงยืดหยุ่นกับลูกค้า
                หากลูกค้าต้องการปรับเปลี่ยนแก้ไขเพิ่มเติมเรามีทีมงานนักพัฒนาที่คอยสนับสนุนการแก้ไขโปรแกรม
                ให้เป็นไปตามความต้องการของลูกค้า
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Module></Module>
    <ScreenShoots></ScreenShoots>

    <!-- ***** Pricing Plane Area *****==== -->
	    <section class="pricing_plane_area" id="pricing">
	        <div class="container">
	            <div class="row">
	                <div class="col-12">
	                    <!-- Heading Text  -->
	                    <div class="section-heading text-center">
	                        <h2>Plans & Pricing</h2>
	                        <div class="line-title-center"></div>
	                    </div>
	                </div>
	            </div>

	            <div class="row">
                <div class="col-12">
                  <div class="row">
                    <div class="col-12 col-md-4 col-lg-4">
	                    <!-- Package Price  -->
	                    <div class="plan-item" style="box-shadow: 0 0 20px 0 rgb(0 0 0 / 10%);">
	                        <!-- Package Text  -->
	                        
	                        <div class="pricing-detail text-center" style="background: white;color: #636d75;">
	                        	<h5>Standard</h5>
	                        </div>
                          <div class="line-title-center-v2"></div>
	                        <div class="package-price text-center">
	                            <h4><span></span></h4>
	                        </div>
	                        <div class="package-description">
	                            <p>Total Users <label class="float-right">ไม่จำกัด</label></p>
	                            <p>Total Devices <label class="float-right">ไม่จำกัด</label></p>
	                            <p>Employee <i class="fas fa-check float-right"></i></p>
	                            <p>Time Attendance <i class="fas fa-check float-right"></i></p>
	                            <p>Payroll <i class="fas fa-check float-right"></i></p>
	                            <p>Customize <i class="fas fa-check float-right"></i></p>
                              <!-- <p style="border-bottom: 0px;height: 267PX;"></p> -->
                              
	                        </div>
	                        <!-- Plan Button  -->
	                        <div class="text-center pricing-btn">
                            <span class="input-group-btn">
                              <button
                                class="btn btn-outline-primary my-4"
                                type="button"
                                @click="showMessage"
                              >
                                ขอทราบราคา
                              </button>
                            </span>
	                        	<!-- <button class="btn btn-outline-primary my-4" type="button">ขอทราบราคา</button> -->
	                        </div> 
	                    </div>
	                </div>
	                <div class="col-12 col-md-4 col-lg-4">
	                    <!-- Package Price  -->
	                    <div class="plan-item" style="box-shadow: 0 0 20px 0 rgb(0 0 0 / 10%);">
	                        <!-- Package Text  -->
	                        
	                        <div class="pricing-detail text-center" style="background: white;color: #636d75;">
	                            	<h5>Professional</h5>
	                        </div>
                          <div class="line-title-center-v2" style="background-color: steelblue;"></div>
	                        <div class="package-price text-center">
	                            <h4><span></span></h4>
	                        </div>
	                        <div class="package-description">
	                            <p>Total Users <label class="float-right">ไม่จำกัด</label></p>
	                            <p>Total Devices <label class="float-right">ไม่จำกัด</label></p>
	                            <p>Employee <i class="fas fa-check float-right"></i></p>
	                            <p>Time Attendance <i class="fas fa-check float-right"></i></p>
	                            <p>Payroll <i class="fas fa-check float-right"></i></p>
	                            <p>Training <i class="fas fa-check float-right"></i></p>
	                            <p>Welfare <i class="fas fa-check float-right"></i></p>
                              <p>Customize <i class="fas fa-check float-right"></i></p>
                              <!-- <p style="border-bottom: 0px;height: 113px;"></p> -->
	                        </div>
	                        <!-- Plan Button  -->
	                        <div class="text-center pricing-btn">
	                        	<span class="input-group-btn">
                              <button
                                class="btn btn-outline-primary my-4"
                                type="button"
                                @click="showMessage"
                              >
                                ขอทราบราคา
                              </button>
                            </span>
	                        </div>
	                    </div>
	                </div>
	                <div class="col-12 col-md-4 col-lg-4">
	                    <!-- Package Price  -->
	                    <div class="plan-item" style="box-shadow: 0 0 20px 0 rgb(0 0 0 / 10%);">
	                        <!-- Package Text  -->
	                        
	                        <div class="pricing-detail text-center" style="background: white;color: #636d75;">
	                        	<h5>Enterprise</h5>
	                        </div>
                          <div class="line-title-center-v2" style="background-color: teal;"></div>
	                        <div class="package-price text-center">
	                            <h4><span></span></h4>
	                        </div>
	                        <div class="package-description">
	                            <p>Total Users <label class="float-right">ไม่จำกัด</label></p>
	                            <p>Total Devices <label class="float-right">ไม่จำกัด</label></p>
	                            <p>Employee <i class="fas fa-check float-right"></i></p>
	                            <p>Time Attendance <i class="fas fa-check float-right"></i></p>
	                            <p>Payroll <i class="fas fa-check float-right"></i></p>
	                            <p>Training <i class="fas fa-check float-right"></i></p>
	                            <p>Welfare <i class="fas fa-check float-right"></i></p>
	                            <p>Evaluate <i class="fas fa-check float-right"></i></p>
	                            <p>Recruitment <i class="fas fa-check float-right"></i></p>
                              <p>Customize <i class="fas fa-check float-right"></i></p>
	                        </div>
	                        <!-- Plan Button  -->
	                        <div class="text-center pricing-btn">
	                        	<span class="input-group-btn">
                              <button
                                class="btn btn-outline-primary my-4"
                                type="button"
                                @click="showMessage"
                              >
                                ขอทราบราคา
                              </button>
                            </span>
	                        </div>
	                    </div>
	                </div>
                  </div>
                </div>
	            </div>
	        </div>
	    </section>
	    <!-- ***** Pricing Plane Area End ***** -->

    <section id="blog" class="section">
      <!-- Container Starts -->
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="blog-item-area wow fadeInUp " data-wow-delay="0.3s">
              <div class="row align-items-center">
                <div class="col-lg-8">
                  <div
                    class="blog-item-text"
                    style="border: 0px;text-align: center;"
                  >
                    <h3 style="font-size: 30px; padding-top: 20px;">
                      <a>Biosoft Document Feature.</a>
                    </h3>
                    <p>
                      คุณสามารถ Download เอกสารคุณสมบัติของผลิตภัณฑ์ Biosoft
                      ได้ที่นี่
                    </p>
                  </div>
                </div>
                <div class="col-lg-3 col-md-3 mr-md-auto">
                  <div
                    class="blog-item-text"
                    style="border: 0px;text-align: center;"
                  >
                    <div class="app-download-btn">
                      <span class="input-group-btn">
                        <button
                          class="btn btn-primary"
                          type="button"
                          @click="loadDocument"
                        >
                          Download Document
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
     <section class="clients_testimonial_area bg-light" id="testimonial">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <!-- Heading Text -->
            <div class="text-center">
              <h2 style="color: #636d75;">คำถามที่พบบ่อย (FAQ)</h2>
              <div class="line-title-center"></div>
              <div class="client">
                  <i class="fa fa-quote-left" aria-hidden="true"></i>
                </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6 col-md-6">
            <div class="fag-text">
                <h4>โปรแกรม biosoft เหมาะกับธุรกิจแบบไหนและขนาดใด</h4>
                  <p>
                    “ โปรแกรม biosoft เหมาะกับทุกธุระกิจและทุกขนาด เพราะเรามีให้เลือกหลาย Module ตามความต้องการ และประเภทธุระกิจของลูกค้า ”
                  </p>
              </div>
          </div>
          <div class="col-12 col-lg-6 col-md-6">
            <div class="fag-text">
                <h4>โปรแกรม biosoft รองรับพนักงานได้กี่คน</h4>
                  <p>
                    “ โปรแกรม biosoft ไม่จำกัดพนักงาน สามารถเพิ่มพนักงานกี่ท่านก็ได้ ”
                  </p>
              </div>
          </div>
          <div class="col-12 col-lg-6 col-md-6">
            <div class="fag-text">
                <h4>โปรแกรม biosoft ขายยังไง</h4>
                  <p>
                    “ โปรแกรม biosoft ขายเป็น Package ซึ่งเรามี 3 Package ให้ลูกค้าได้เลือก <span style="font-weight: bold;">Standard</span>, <span style="font-weight: bold;">Professional</span>, <span style="font-weight: bold;">Enterprise</span>  ”
                  </p>
              </div>
          </div>
          <div class="col-12 col-lg-6 col-md-6">
            <div class="fag-text">
                <h4>โปรแกรม biosoft มีระบบ HRD มั้ย</h4>
                  <p>
                    “ แน่นอน biosoft มีระบบ HRD ”
                  </p>
              </div>
          </div>
          <div class="col-12 col-lg-6 col-md-6">
            <div class="fag-text">
                <h4>โปรแกรม biosoft เป็นระบบ On Cloud หรือ OnPremis</h4>
                  <p>
                    “ โปรแกรม biosoft เป็นระบบแบบ OnPremis ทางเราจะนำ Software ไปติดตั้งที่ลูกค้า ซึ่งลูกค้าจะนำ Software 
                    ไปติดตั้งบน Cloud ของลูกค้าเอง ทางเราก็บริการติดตั้งให้ฟรี ”
                  </p>
              </div>
          </div>
          <div class="col-12 col-lg-6 col-md-6">
            <div class="fag-text">
                <h4>โปรแกรม biosoft มีระบบ Online ESS หรือไม่</h4>
                  <p>
                    “ โปรแกรม biosoft มีระบบ ESS เพื่อสนับสนุนการทำงานของผู้บริหาร เพื่อให้สามารถเข้าใช้งานได้ง่ายและสะดวกสบาย ”
                  </p>
              </div>
          </div>
        </div>
      </div>
    </section>
    <!-- <ContactUs></ContactUs> -->
  </div>
</template>

<script>
const HomeFeatures = () => import("./components/HomeFeatures");
const ScreenShoots = () => import("./components/ScreenShoots");
const ContactUs = () => import("./components/ContactUs");
const Module = () => import("./components/Module");
const VLazyImage = () => import("v-lazy-image");
const carousel = () => import("vue-owl-carousel");
export default {
  name: "app",
  components: {
    VLazyImage,
    carousel,
    HomeFeatures,
    ScreenShoots,
    Module,
    ContactUs,
  },
  data: function() {
    return {
      images: [
        "assets/images/web/web9.png",
        "assets/images/web/web10.png",
        // "https://img-website-biosoft.s3-ap-southeast-1.amazonaws.com/web10.png",
        // "https://img-website-biosoft.s3-ap-southeast-1.amazonaws.com/web11.png",
      ],
    };
  },
  methods: {
    showMessage() {
      this.$swal({
        icon: 'info',
        title: '<strong>ติดต่อ Sale</strong>',
        html: '<div>LINE ID : @biosoft<br/>Tel. : 02-054-4800<br />Mobile : 063-464-7568<br />Email : marketing@biosoftplus.com</div>',
      });
    },
    goToWeb() {
      this.$router.replace("/WebApp");
    },
    goToMobile() {
      this.$router.replace("/WebApp");
    },
    goToWin() {
      this.$router.replace("/WebApp");
    },
    loadDocument() {
      window.open(
        "https://docs.google.com/uc?export=download&id=16M0jzMbZhmVvrq1_gS6hDs6XLyEzNlM0",
        "_blank"
      );
    },
    // loadDocumentMobile() {
    //   window.open(
    //     "https://docs.google.com/uc?export=download&id=1Hve8jzP3sDkHZCcYTSaVVfcqHPNqwLR3",
    //     "_blank"
    //   );
    // },
  },
  mounted: function() {
    this.$nextTick(function() {
      window.scrollTo(0, 0);
    });
  },
};
</script>

<style scoped>
@import '~sweetalert2/dist/sweetalert2.min.css';
.v-lazy-image {
  filter: blur(10px);
  transition: filter 3s;
}
.v-lazy-image-loaded {
  filter: blur(0);
}
.load-app {
  background: snow;
}
.app-download-area {
  margin-top: 0px;
}
.goToWeb {
  cursor: pointer;
  font-weight: bold;
}
.goToWeb:hover {
  color: #43cea2;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.line-title-center-v2 {
    width: 200px;
    height: 4px;
    background-color: #43cea2;
    margin-top: 15px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
}
.fag-text {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
}
.fag-text > p{
    color: #636d75;
    font-size: 15px;
}
.fag-text > h4{
  color: #41c9a2;
  font-weight: 100;
  font-size: 21px;
}
</style>
